import React from 'react';
import s from './PayNowLater.scss';
import classnames from 'classnames';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {OrderSummaryDataHook} from '../OrderSummary';
import {useTranslation} from '@wix/yoshi-flow-editor';

export const PayNowLater = () => {
  const {estimatedTotals} = useControllerProps().cartStore;
  const {t} = useTranslation();

  const payLater = estimatedTotals.payLater.total.convertedAmount;
  const formattedPayLater = estimatedTotals.payLater.total.formattedConvertedAmount;

  const formattedPayNow = estimatedTotals.payNow.total.formattedConvertedAmount;

  return (
    payLater > 0 && (
      <div data-hook={OrderSummaryDataHook.PayNowLaterRoot} className={classnames(s.topDivider, s.root)}>
        <dl>
          <dt>
            <span data-hook={OrderSummaryDataHook.PayNowTitle} className={s.payNow}>
              {t('cart.orderSummary.amountDueNow.label')}
            </span>
          </dt>
          <dd
            data-hook={OrderSummaryDataHook.PayNowValue}
            data-wix-payNow-value={OrderSummaryDataHook.PayNowValue}
            className={s.payNow}>
            {formattedPayNow}
          </dd>
        </dl>
        <dl>
          <dt>
            <span data-hook={OrderSummaryDataHook.PayLaterTitle}>{t('cart.orderSummary.amountDueLater.label')}</span>
          </dt>
          <dd
            data-hook={OrderSummaryDataHook.PayLaterValue}
            data-wix-payLater-value={OrderSummaryDataHook.PayLaterValue}>
            {formattedPayLater}
          </dd>
        </dl>
      </div>
    )
  );
};
