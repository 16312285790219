import {UseControllerPropsResult} from '../controllers/ControllerContext';
import {LineItemModel} from '../models/LineItem.model';

export const getIsQuantityErrorNeeded = (lineItemQuantityAvailable: number, quantity: number) => {
  return lineItemQuantityAvailable !== 0 && quantity > getMaxItemQuantity(lineItemQuantityAvailable);
};

export const getIsOutOfStock = (lineItemQuantityAvailable: number) => {
  return lineItemQuantityAvailable === 0;
};

export const getMaxItemQuantity = (lineItemQuantityAvailable: number) => {
  return lineItemQuantityAvailable || 99_999;
};
export const getHref = (cartStore: UseControllerPropsResult['cartStore'], lineItem: LineItemModel) => {
  return cartStore.manifest[lineItem.catalogReference.catalogItemId].href;
};

export const shouldPresentDetails = (lineItem: LineItemModel) => {
  return lineItem.descriptionLines.length > 0;
};
